import React, { useState } from 'react';
import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import questionimg from '../img/svg/question.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import Error from '../errordata/Error'

const initialData = [
    { id: 1, name: 'Apple', category: 'Fruit' },
    { id: 2, name: 'Banana', category: 'Fruit' },
    { id: 3, name: 'Carrot', category: 'Vegetable' },
    { id: 4, name: 'Orange', category: 'Fruit' },


];

// const categories = ['Fruit', 'Vegetable'];

const Pastpaperexamcontent_3 = () => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredData, setFilteredData] = useState(initialData);

    const handleSelectChange = (e) => {
        setSelectedCategory(e.target.value);
        const filteredData = initialData.filter(item => item.category === e.target.value);
        setFilteredData(filteredData);
    };

    return (
        <>

            <section className='Pastpaperexamcontent-page'>
                <Error />

            </section>
        </>
    )
}

export default Pastpaperexamcontent_3