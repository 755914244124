import React from 'react';
import './loader.css';
import loder from '../img/logopastpapers.png';

const Loader = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="loader">
              <div className="cube-container">
                <div id="cube">
                  {/* <figure className="face front">X</figure>
                  <figure className="face back">Y</figure>
                  <figure className="face left">Sin</figure>
                  <figure className="face right">Tan</figure>
                  <figure className="face top">Cos</figure>
                  <figure className="face bottom">π</figure> */}

                </div>
              </div>
              <img src={loder} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
