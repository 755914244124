import React from 'react';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import Pastpaperexam from '../../pastpaperexam/Pastpaperexam';

const home = () => {
  return (
    <>
      <Header />
      <Pastpaperexam />
      <Footer />
    </>
  );
};

export default home;
