import React from 'react';
import './footer.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import img_1 from '../../img/logopastpapers.png';
import img_8 from '../../img/logo/white-logo.png';
import img_2 from '../../img/icon/Social-Icons-1.png';
import img_3 from '../../img/icon/Social-Icons-2.png';
import img_4 from '../../img/icon/Social-Icons-3.png';
import img_5 from '../../img/icon/Social-Icons-4.png';
import img_6 from '../../img/icon/Social-Icons-5.png';
import img_7 from '../../img/icon/Social-Icons-6.png';

const Footer = () => {
  return (
    <>
      <section className="footer pt-60 desktop-view">
        <Container>


          <Row>
            <Col sm={12}>
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src={img_1}
                    className="drack"
                  />
                </Link>
                <Link to="/">
                  {/* <img
                    src={img_8}
                    className="white"
                  /> */}
                </Link>

                <Col sm={7}>
                  <div className="social-icon">
                    <Link
                      href="#action1"
                      className="footer-link-style">
                      <img src={img_2} />
                    </Link>
                    <Link
                      href="#action1"
                      className="footer-link-style">
                      <img src={img_3} />
                    </Link>
                    <Link
                      href="#action1"
                      className="footer-link-style">
                      <img src={img_4} />
                    </Link>
                    <Link
                      href="#action1"
                      className="footer-link-style">
                      <img src={img_5} />
                    </Link>
                    <Link
                      href="#action1"
                      className="footer-link-style">
                      <img src={img_6} />
                    </Link>
                    <Link
                      href="#action1"
                      className="footer-link-style">
                      <img src={img_7} />
                    </Link>
                  </div>
                </Col>
                <p>Let your academic evolution begin here.</p>
              </div>
            </Col>
          </Row>

          <div className="line-2"></div>
          <Row>
            <Col sm={12}>
              <p className="text-center pt-30 footer-bottom">
                © 2023 Only Past Papers • All Rights Reserved
              </p>
            </Col>
          </Row>
        </Container>
      </section>

    </>
  );
};

export default Footer;
