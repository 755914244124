import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./layout/home/Home";

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
