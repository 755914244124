import React from 'react'
import { useGetAllpastpapercoursesQuery } from '../services/aplms';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import Pastpapersubject from './Pastpapersubject';
import { Container, Row, Col, Form, Table, Dropdown, Button } from 'react-bootstrap';

const Pastpapercouser = ({ curriculumId, handleFilterChange }) => {
    const { data, isLoading, isError } = useGetAllpastpapercoursesQuery(curriculumId);



    if (isLoading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    if (isError || !data || data.length === 0) {
        return (
            <div>
                <Error />
            </div>
        );
    }



    return (
        <>
            <div className='filter-drop-menu-p dropdown-button-fillter-style-main-div'>
                {data.map((datas) => {
                    return (
                        <div key={datas.id} className='dropdown-button-fillter-style'>
                            {/* <Button key={datas.id} onClick={() => handleFilterChange('course', datas.id)('curriculum', curriculumId)}  >
                                {datas.name}
                            </Button> */}

                            <Button onClick={() => {
                                handleFilterChange('curriculum', curriculumId);
                                handleFilterChange('course', datas.id);
                            }}>
                                {datas.name}
                            </Button>


                        </div>
                    );
                })}
            </div>
        </>
    )
}

export default Pastpapercouser

