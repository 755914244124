import React, { useState } from 'react';
import './Pastpaperexam.css';
// import Header from '../layout/header/Header';
// import Footer from '../layout/footer/Footer';
import { Tab, Tabs, TabContent, Nav, Offcanvas, Button } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Pastpaperexamcontent from './Pastpaperexamcontent';
import Pastpaperexamcontent_2 from './Pastpaperexamcontent_2';
// img
import Img_1 from '../img/svg/tab-top-1.svg'
import Img_2 from '../img/svg/tab-top-2.svg'
import Img_3 from '../img/svg/tab-top-3.svg'
import Img_4 from '../img/svg/tab-top-4.svg'
import Img_5 from '../img/svg/tab-top-5.svg'
import Img_6 from '../img/svg/tab-top-6.svg'
import Img_7 from '../img/svg/tab-top-1.svg'
import Img_8 from '../img/svg/tab-top-8.svg'
import Img_9 from '../img/svg/tab-top-9.svg'
import Img_10 from '../img/svg/left-icon.svg'
import Img_11 from '../img/svg/right-icon.svg'
import Pastpaperexamcontent_3 from './Pastpaperexamcontent_3';

const Pastpaperexam = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="ap-mobile-view-header Pastpaperexam">
                <Container className='pastpaperexam_page'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                        <Row>
                            <Col sm={12}>
                                <Nav variant="pills" className="Pastpaperexam-tab-main desktop-view">
                                    <Nav.Item className='nav-tabs'>
                                        <Nav.Link eventKey="1">
                                            <div className='Pastpaperexam-tab-herder'><img src={Img_1} alt="Image" /><div className='margin-left-10'><h4>PAST PAPERS</h4><p>free, Pdf, downloadable</p></div></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-tabs'>
                                        <Nav.Link eventKey="2">
                                            <div className='Pastpaperexam-tab-herder'><img src={Img_2} alt="Image" /><div className='margin-left-10'><h4>TOPICAL PAST PAPERS</h4><p>not free, online, not downloadable</p></div></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-tabs'>
                                        <Nav.Link eventKey="3">
                                            <div className='Pastpaperexam-tab-herder'><img src={Img_3} alt="Image" /><div className='margin-left-10'><h4>MCQ PAST PAPERS</h4><p>not free, online practice</p></div></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-tabs'>
                                        <Nav.Link eventKey="4">
                                            <div className='Pastpaperexam-tab-herder'><img src={Img_4} alt="Image" /><div className='margin-left-10'><h4>NON-TOPICAL PAST PAPERS</h4><p>free, online, not downloadable</p></div></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-tabs'>
                                        <Nav.Link eventKey="5">
                                            <div className='Pastpaperexam-tab-herder'><img src={Img_5} alt="Image" /><div className='margin-left-10'><h4>NUM8ERS QUESTION BANK</h4><p>free, online, not downloadable</p></div></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-tabs'>
                                        <Nav.Link eventKey="6">
                                            <div className='Pastpaperexam-tab-herder'><img src={Img_6} alt="Image" /><div className='margin-left-10'><h4>SOLVED PAST PAPERS</h4><p>not free, Pdf, downloadable</p></div></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-tabs'>
                                        <Nav.Link eventKey="7">
                                            <div className='Pastpaperexam-tab-herder'><img src={Img_7} alt="Image" /><div className='margin-left-10'><h4>ACT, SAT, AP</h4><p>free, online, not downloadable</p></div></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-tabs'>
                                        <Nav.Link eventKey="8">
                                            <div className='Pastpaperexam-tab-herder'><img src={Img_8} alt="Image" /><div className='margin-left-10'><h4>BUILD EXAM OR WORKSHEET</h4><p>not free, convert to pdf, printable</p></div></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-tabs'>
                                        <Nav.Link eventKey="9">
                                            <div className='Pastpaperexam-tab-herder'><img src={Img_9} alt="Image" /><div className='margin-left-10'><h4>BUILD QUESTION LIST</h4><p>free, online, not downloadable</p></div></div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                {/* <div className='mobile-view'>
                                    <div className='top-menu-button-show and hide'>
                                        <Button onClick={handleShow}>
                                            <img src={Img_10} />
                                        </Button>
                                    </div>
                                    <Offcanvas show={show} onHide={handleClose}>
                                        <Offcanvas.Body>
                                            <Nav variant="pills" className="Pastpaperexam-tab-main mobile-view ">
                                                <Nav.Item className='nav-tabs'>
                                                    <Nav.Link eventKey="1">
                                                        <div className='Pastpaperexam-tab-herder'><img src={Img_1} alt="Image" /><div className='margin-left-10'><h4>PAST PAPERS</h4><p>free, Pdf, downloadable</p></div></div>
                                                    </Nav.Link>
                                                    <Offcanvas.Header closeButton>
                                                    </Offcanvas.Header>
                                                </Nav.Item>
                                                <Nav.Item className='nav-tabs'>
                                                    <Nav.Link eventKey="2">
                                                        <div className='Pastpaperexam-tab-herder'><img src={Img_2} alt="Image" /><div className='margin-left-10'><h4>TOPICAL PAST PAPERS</h4><p>not free, online, not downloadable</p></div></div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='nav-tabs'>
                                                    <Nav.Link eventKey="3">
                                                        <div className='Pastpaperexam-tab-herder'><img src={Img_3} alt="Image" /><div className='margin-left-10'><h4>MCQ PAST PAPERS</h4><p>not free, online practice</p></div></div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='nav-tabs'>
                                                    <Nav.Link eventKey="4">
                                                        <div className='Pastpaperexam-tab-herder'><img src={Img_4} alt="Image" /><div className='margin-left-10'><h4>NON-TOPICAL PAST PAPERS</h4><p>free, online, not downloadable</p></div></div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='nav-tabs'>
                                                    <Nav.Link eventKey="5">
                                                        <div className='Pastpaperexam-tab-herder'><img src={Img_5} alt="Image" /><div className='margin-left-10'><h4>NUM8ERS QUESTION BANK</h4><p>free, online, not downloadable</p></div></div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='nav-tabs'>
                                                    <Nav.Link eventKey="6">
                                                        <div className='Pastpaperexam-tab-herder'><img src={Img_6} alt="Image" /><div className='margin-left-10'><h4>SOLVED PAST PAPERS</h4><p>not free, Pdf, downloadable</p></div></div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='nav-tabs'>
                                                    <Nav.Link eventKey="7">
                                                        <div className='Pastpaperexam-tab-herder'><img src={Img_7} alt="Image" /><div className='margin-left-10'><h4>ACT, SAT, AP</h4><p>free, online, not downloadable</p></div></div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='nav-tabs'>
                                                    <Nav.Link eventKey="8">
                                                        <div className='Pastpaperexam-tab-herder'><img src={Img_8} alt="Image" /><div className='margin-left-10'><h4>BUILD EXAM OR WORKSHEET</h4><p>not free, convert to pdf, printable</p></div></div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className='nav-tabs'>
                                                    <Nav.Link eventKey="9">
                                                        <div className='Pastpaperexam-tab-herder'><img src={Img_9} alt="Image" /><div className='margin-left-10'><h4>BUILD QUESTION LIST</h4><p>free, online, not downloadable</p></div></div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Offcanvas.Body>
                                    </Offcanvas>
                                </div> */}
                            </Col>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="1" className='tab_1'>
                                        <Pastpaperexamcontent />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="2" className='tab_2'>
                                        <Pastpaperexamcontent_2 />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="3" className='tab_3'>
                                        <Pastpaperexamcontent_3 />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="4" className='tab_4'>
                                        <Pastpaperexamcontent_3 />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="5" className='tab_5'>
                                        <Pastpaperexamcontent_3 />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="6" className='tab_6'>
                                        <Pastpaperexamcontent_3 />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="7" className='tab_7'>
                                        <Pastpaperexamcontent_3 />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="8" className='tab_8'>
                                        <Pastpaperexamcontent_3 />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="9" className='tab_9'>
                                        <Pastpaperexamcontent_3 />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>

            </div>
            <div className='footer-style-top'>

            </div>

        </>
    )
}

export default Pastpaperexam