import React, { useState, useEffect, useContext } from 'react';
import './header.css';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import img_2 from '../../img/icon/Social-Icons-1.png';
import img_3 from '../../img/icon/Social-Icons-2.png';
import img_4 from '../../img/icon/Social-Icons-3.png';
import img_5 from '../../img/icon/Social-Icons-4.png';
import img_6 from '../../img/icon/Social-Icons-5.png';
import img_7 from '../../img/icon/Social-Icons-6.png';

import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Black_Logo from '../../img/logopastpapers.png';
// import Whote_Logo from '../../img/logo/white-logo.png';



import { useGetAllcurriculumQuery } from '../../services/aplms';

import Loader from '../../loader/Loader';
import Error from '../../errordata/Error';


import Dropdown from 'react-bootstrap/Dropdown';
import { getToken } from '../../services/LocalStorageService';



const Header = ({ name, ...props }) => {


  // header new code start

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const header = document.getElementById('header-number-project');
    if (header) {
      if (scrolled) {
        header.classList.add('header-number-project');
      } else {
        header.classList.remove('header-number-project');
      }
    }
  }, [scrolled]);

  // end header new code


  const toggleShowA = () => setShowA(!showA);
  const [showA, setShowA] = useState(false);

  const token = getToken();




  const curriculum = useGetAllcurriculumQuery();

  if (curriculum.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (curriculum.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (curriculum.isError) return <h1>data not found </h1>;

  return (
    <>
      <Navbar
        expand="lg"
        className="desktop-view"
        id='header-number-project'>
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              className="light"
              src={Black_Logo}
            />

          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="mobile-button"
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mx-auto my-2 my-lg-0 header-center-content"
              style={{ maxHeight: '100px' }}
              navbarScroll>
              <Dropdown
                className="nav-item dropdown megamenu"
                autoClose={false}>


              </Dropdown>











            </Nav>




          </Navbar.Collapse>


          <Col sm={3}>
            <div className="social-icon">
              <Link
                href="#action1"
                className="footer-link-style">
                <img src={img_2} />
              </Link>
              <Link
                href="#action1"
                className="footer-link-style">
                <img src={img_3} />
              </Link>
              <Link
                href="#action1"
                className="footer-link-style">
                <img src={img_4} />
              </Link>
              <Link
                href="#action1"
                className="footer-link-style">
                <img src={img_5} />
              </Link>
              <Link
                href="#action1"
                className="footer-link-style">
                <img src={img_6} />
              </Link>
              <Link
                href="#action1"
                className="footer-link-style">
                <img src={img_7} />
              </Link>
            </div>
          </Col>
        </Container>
      </Navbar>






    </>
  );
};

export default Header;
